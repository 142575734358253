import AppBody from '../AppBody'
import { RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import React, { useState } from 'react'
import { Wrapper, BottomGrouping, AirdropCallbackError } from '../../components/airdrop/styleds'
import { ButtonLight, ButtonConfirmed } from '../../components/Button'
import { useActiveWeb3React } from '../../hooks'
import { useClaimInfo } from '../../hooks/useClaim'
import { useWalletModalToggle } from '../../state/application/hooks'

import Logo from '../../assets/images/logomain.png'
import LogoDark from '../../assets/images/logomain.png'
import { useDarkModeManager } from '../../state/user/hooks'
import { useIsTransactionPending, useTransactionAdder } from 'state/transactions/hooks';
import { calculateGasMargin, getAirdropContract } from 'utils';
import { TransactionResponse } from '@ethersproject/abstract-provider';
import { BigNumber } from '@ethersproject/bignumber';
import { ClaimInfo } from 'utils/getClaimInfo';


const AirdropWrapper = styled.div`
  width: 100%;
  max-width: 1400px;
  margin: 42px 0px;
  display: flex;
  justify-content: center;
  text-align: center;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }

`

const AirdropTitle = styled.div`
  font-size: 20px;
  margin: 24px 0px;
`

const AirdropContent = styled.div`
  font-size: 16px;
  margin: 24px 0px;
`

export default function Airdrop({ history }: RouteComponentProps) {

  const [darkMode] = useDarkModeManager()

  const { account, chainId, library } = useActiveWeb3React()


  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()

  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)
  const [txHash, setTxHash] = useState<string>('')

  const [errorMessage, setErrorMessage] = useState<string>('')
  const isPending = useIsTransactionPending(txHash);

  const claimInfo: ClaimInfo | null = useClaimInfo(account);

  // tx sending
  const addTransaction = useTransactionAdder()
  async function claim() {
    if (!chainId || !library || !account) return
    const contract = getAirdropContract(chainId, library, account)

    if (!claimInfo) return

    let estimate,
      method: (...args: any) => Promise<TransactionResponse>,
      args: Array<string | string[] | number>,
      value: BigNumber | null;

    estimate = contract.estimateGas.claim
    method = contract.claim
    args = [
      claimInfo?.index,
      account,
      claimInfo?.amount,
      claimInfo?.proof
    ]
    value = null

    console.log(attemptingTxn, txHash);

    setAttemptingTxn(true)
    await estimate(...args, value ? { value } : {})
      .then(estimatedGasLimit =>
        method(...args, {
          ...(value ? { value } : {}),
          gasLimit: calculateGasMargin(estimatedGasLimit)
        }).then(response => {
          setAttemptingTxn(false)

          addTransaction(response, {
            summary:
              'Claim Successed.'
          })
          setTxHash(response.hash)
          setErrorMessage('');
        })
      )
      .catch(error => {
        setAttemptingTxn(false)
        // we only care if the error is something _other_ than the user rejected the tx
        if (error?.code !== 4001) {
          setErrorMessage(error.message);
        }
        else {
          setErrorMessage('User rejected transaction.');
        }
      })
  };

  return (
    <>

      <img width={'235px'} style={{ marginBottom: '15px', marginTop: '-40px' }} src={darkMode ? LogoDark : Logo} alt="logo" />

      <AirdropWrapper>
        <AppBody>
          <Wrapper>

            <AirdropTitle>
              Connect Your Wallet & Claim Your ETH HOKK Tokens
            </AirdropTitle>

            <AirdropContent>
              Your Claimable HOKK Tokens: {claimInfo ? BigNumber.from(claimInfo.amount).toString() : 0}
            </AirdropContent>

            <BottomGrouping>
              {!account ? (
                <ButtonLight onClick={toggleWalletModal} style={{ width: '240px' }}>Connect Wallet</ButtonLight>
              ) : (
                <ButtonConfirmed disabled={attemptingTxn || !claimInfo || isPending} onClick={claim} style={{ width: '240px' }}>
                  {'Claim Token'}
                </ButtonConfirmed>
              )}
            </BottomGrouping>

            {errorMessage ? <AirdropCallbackError error={errorMessage} /> : null}

          </Wrapper>
        </AppBody>

      </AirdropWrapper>
    </>
  )
}
